const notAllowed = 'not-allowed'

export const container = {
  maxWidth: '320px',
  minWidth: '280px',
  padding: '10px',
  bg: 'muted',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  '& div': { ':focus': { outline: 'none', backgroundColor: 'transparent' } },
}

export const buttons = {
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  flexWrap: 'wrap',
  width: '100%',
  '& button': {
    margin: 0,
    mt: '10px',
  },
  '& button span': {
    flexDirection: 'column !important;',
  },
}

export const flex = {
  display: 'flex',
  justifyContent: 'space-around',
}

export const selects = {
  display: 'flex',
  height: 'auto',
  justifyContent: 'space-between',
  my: '5px',
}

export const instrument = {
  p: '11px 14px',
  my: '5px',
  bg: 'primary',
  color: 'text',
  cursor: 'pointer',
  '&:hover': {
    bg: 'secondary',
  },
  display: 'flex',
  justifyContent: 'space-between',
}

export const amounts = colorMode => ({
  ...flex,
  my: '5px',
  width: '100%',
  '& button': {
    border: 'none',
    width: '40px',
    height: '32px',
    p: '5px 0px',
    bg: 'primary',
    color: 'text',
    '&:hover': {
      bg: 'secondary',
    },
    '&:active': {
      border: theme => theme.borders.secondary[colorMode],
      borderColor: theme => theme.colors.green,
    },
  },
})

export const input = {
  width: '100%',
  bg: 'primary',
  color: 'text',
  border: 'none',
  fontSize: 3,
  '&:hover': {
    bg: 'secondary',
  },
  '&:disabled': {
    border: '10px solid',
    bg: 'disabled',
    //color: 'noEdit',
    cursor: notAllowed,
  },
}

export const disabled = {
  bg: 'disabled',
  //color: 'noEdit',
  color: 'text',
  cursor: notAllowed,
  '&:hover': {
    bg: 'disabled',
  },
  '& input, [class*=MuiSelect-select][class*=MuiSelect-disabled]': {
    bg: 'disabled',
    color: 'text',
    cursor: notAllowed,
  },
}

export const mediumSize = {
  height: '36px',
  fontSize: '2',
}

export const tradingCapacitiy = {
  // mt: '3px',
  pl: '0px',
  width: '47%',
  // height: '15px',
  // fontSize: '13px',
  // // pt: '3px',
  // pl: '4px',
  // pr: '4px',
  // height: '15px',
  // pt: '2px',
  // pb: '1px',
}

export const inputBase = {
  pl: '4px',
  pr: '4px',
  width: '37px',
  height: '15px',
  fontSize: '13px',
  pt: '2px',
  pb: '1px',
}

export const orkTipography = {
  color: 'text',
  fontSize: '13px',
}


export const endAdornment = {
  color: 'adornment',
  width: 'max-content',
}
